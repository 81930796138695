<template>
  <div
    class="coin_detail"
    :class="[theme == 'light' ? 'bg_light' : 'bg_dark']"
    v-if="currentDetail"
  >
    <div
      class="detail_top"
      style="display: flex;
    align-items: center;"
    >
      <img :src="currentDetail.logoUrl" />
      <p>{{ currentDetail.currencyName }}{{ currentDetail.currencyCode }}</p>
    </div>
    <div
      class="header flex_column"
      :class="[theme == 'light' ? 'header_light' : 'header_dark']"
    >
      <div class="header_top">
        <div class="number flex_row">
          <p>{{ currentDetail.price | formateMoney }}</p>
          <!-- <p>NO.1</p> -->
        </div>
        <div class="conversion flex_row">
          <p :class="[theme == 'light' ? 'color_2_light' : 'color_2_dark']">
            ≈${{ currentDetail.price | toDecimal }}
          </p>
          <div class="d_1 flex_row">
            <img
              src="@/assets/icon/up.png"
              v-if="currentDetail.changePercentage > 0"
              alt
            />
            <img src="@/assets/icon/down.png" v-else alt />
            <p
              :class="[
                currentDetail.changePercentage > 0 ? 'colorUp' : 'colorDown'
              ]"
            >
              {{ currentDetail.changePercentage }}%
            </p>
          </div>
        </div>
      </div>
      <div class="header_bottom">
        <div class="intro_wrapper flex_row">
          <div class="intro_item flex_row">
            <p :class="[theme == 'light' ? 'color_3_light' : 'color_3_dark']">
              {{ $t("市值总额") }}
            </p>
            <p :class="[theme == 'light' ? 'color_2_light' : 'color_2_dark']">
              {{ currentDetail.marketCap | formateMoney }}
            </p>
          </div>
          <div class="intro_item flex_row">
            <p :class="[theme == 'light' ? 'color_3_light' : 'color_3_dark']">
              {{ $t("24H成交额") }}
            </p>
            <p :class="[theme == 'light' ? 'color_2_light' : 'color_2_dark']">
              {{ currentDetail.volume24 | formateMoney }}
            </p>
          </div>
        </div>
        <div class="intro_wrapper flex_row">
          <div class="intro_item flex_row">
            <p :class="[theme == 'light' ? 'color_3_light' : 'color_3_dark']">
              {{ $t("24H成交量") }}
            </p>
            <p :class="[theme == 'light' ? 'color_2_light' : 'color_2_dark']">
              {{ currentDetail.amount24 | toRound
              }}{{ currentDetail.currencyCode }}
            </p>
          </div>
          <div class="intro_item flex_row">
            <p :class="[theme == 'light' ? 'color_3_light' : 'color_3_dark']">
              {{ $t("换手率") }}
            </p>
            <p :class="[theme == 'light' ? 'color_2_light' : 'color_2_dark']">
              {{ currentDetail.handTurnOverRate | toDecimal2 }}%
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 图表 -->
    <div
      class="chart"
      :class="[
        theme == 'light' ? 'border_bottom16_light' : 'border_bottom16_dark'
      ]"
    >
      <!-- <div class="nav_wrapper flex_row" :class="[theme=='light'?'nav_wrapper_light':'nav_wrapper_dark']">
          <div class="nav flex_row">
            <div class="nav_item flex_row" :class="{active: active == 'chart'}" @click="handleChange('chart')">
              <img v-if="active == 'chart'" src="@/assets/icon/det_icon_graph_nor_s.png" alt="">
              <img v-else src="@/assets/icon/det_icon_graph_nor.png" alt="">
            </div>
            <div class="nav_item flex_row" :class="{active: active == 'line'}" @click="handleChange('line')">
              <img v-if="active == 'line'"  src="@/assets/icon/det_icon_graph2_sel.png" alt="">
              <img v-else src="@/assets/icon/det_icon_graph2_nor.png" alt="">
            </div>
         </div>
      </div>-->
      <div class="chart_detail">
        <!-- <Chart v-show="active == 'chart'" /> -->
        <Kline />
      </div>
    </div>
    <mt-navbar
      :class="[theme == 'light' ? 'bg_light' : 'bg_dark']"
      v-model="selected"
    >
      <mt-tab-item class="navBar" id="market">{{ $t("行情") }}</mt-tab-item>
      <mt-tab-item class="navBar" id="intro">{{ $t("简介") }}</mt-tab-item>
      <!-- <mt-tab-item class="navBar" id="history">{{$t('历史数据')}}</mt-tab-item> -->
    </mt-navbar>
    <Market v-if="selected == 'market'" />
    <Intro v-if="selected == 'intro'" />
    <!-- <History v-if="selected == 'history'" /> -->
  </div>
</template>
<script>
import Market from "./components/market";
import Intro from "./components/intro";
import Chart from "./components/chart";
import Kline from "./components/kline";
import History from "./components/history";
import { marketDetail } from "@/api/index";
export default {
  components: {
    Market,
    Intro,
    Chart,
    Kline,
    History
  },
  data() {
    return {
      selected: "market",
      active: "chart",
      currentDetail: "",
      param: {
        currencyId: "",
        language: ""
      },
      theme: ""
    };
  },
  filters: {
    formateMoney: function(value) {
      let currencyCode = localStorage.getItem("quoteCurrencyCode");
      let priceConversion = JSON.parse(localStorage.getItem("priceConversion"));
      let currencySymbol = {
        CNY: "￥",
        USD: "$",
        KRW: "₩",
        JPY: "￥"
      };
      let lastValue = "";
      let rate = priceConversion[currencyCode].currencyPrice;
      lastValue = (value * rate).toFixed(4);
      lastValue = parseFloat(lastValue);
      if (lastValue == 0.0) {
        lastValue = 0;
      }
      let covertValue = value * rate; //转换后的值
      if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 100000000 > 1
      ) {
        let localeValue = covertValue / 100000000;
        localeValue = parseFloat(localeValue.toFixed(2));
        lastValue = `${localeValue}亿`;
      } else if (
        currencyCode == "CNY" &&
        Number.parseInt(covertValue) / 10000 > 1
      ) {
        let localeValue = covertValue / 10000;
        localeValue = parseFloat(localeValue.toFixed(2));
        lastValue = `${localeValue}万`;
      } else if (Number.parseInt(covertValue) / 1000000000 > 1) {
        let localeValue = covertValue / 1000000000;
        localeValue = parseFloat(localeValue.toFixed(2));
        lastValue = `${localeValue}B`;
      } else if (Number.parseInt(covertValue) / 1000000 > 1) {
        let localeValue = covertValue / 1000000;
        localeValue = parseFloat(localeValue.toFixed(2));
        lastValue = `${localeValue}M`;
      }
      let str = `${currencySymbol[currencyCode]}${lastValue}`;
      return str;
    },
    formateTime(value) {
      return value.substring(0, 11);
    },
    toDecimal(value) {
      const val = value ? value : 0;
      return val.toFixed(4);
    },
    toDecimal2(value) {
      if (value) {
        return value.toFixed(2);
      } else {
        return 0;
      }
    },
    toRound(value) {
      let val = Number.parseInt(value);
      return val.toLocaleString();
    }
  },
  methods: {
    handleChange(val) {
      this.active = val;
    },
    getDetail() {
      marketDetail(this.param).then(res => {
        if (res.resultStatus) {
          this.currentDetail = res.resultData;
        }
      });
    },
    setTime() {
      //设置定时器
      this.clearTimeSet = setInterval(() => {
        this.getDetail();
      }, 60000);
    },
    // 清楚定时器
    clearTime() {
      //清除定时器
      clearInterval(this.clearTimeSet);
    }
  },
  created() {
    if (this.$route.query.currencyId) {
      this.param.currencyId = this.$route.query.currencyId;
      this.param.language = localStorage.getItem("currentNation");
      this.theme = "light";
      this.getDetail();
      this.setTime();
    }
  },
  destroyed() {
    console.log("销毁");
    clearInterval(this.clearTimeSet);
  }
};
</script>
<style lang="scss" scoped>
@import "./theme.scss";
@import "@/styles/common.scss";
.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex_column {
  display: flex;
  flex-direction: column;
}
.navBar {
  font-size: 24px !important;
}
.coin_detail {
  min-height: 100%;
  width: 100%;
  .detail_top {
    margin-top: 28px;
    margin-left: 32px;
    img {
      width: 50px;
      height: 50px;
    }
    p {
      margin-left: 12px;
      font-size: 28px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #4c4c4c;
      line-height: 40px;
    }
  }
}
.header {
  position: relative;
  height: 310px;
  width: 100%;
  padding: 0px 32px;
  box-sizing: border-box;
  justify-content: space-around;
  .header_top {
    position: relative;
    margin-top: 30px;
    .number {
      justify-content: space-between;
      p:nth-child(1) {
        font-size: 60px;
        color: #51b463;
      }
      p:nth-child(2) {
        font-size: 28px;
        color: #51b463;
      }
    }
    .conversion {
      margin-top: 8px;
      p:nth-child(1) {
        font-size: 28px;
        position: relative;
        margin-right: 40px;
      }
      .d_1 {
        position: relative;
        img {
          width: 19px;
          height: 24px;
          position: relative;
          margin-right: 20px;
        }
        p {
          font-size: 28px;
          position: relative;
        }
      }
    }
  }
  .header_bottom {
    .intro_wrapper {
      width: 100%;
      position: relative;
      justify-content: space-between;
      margin-top: 24px;
      .intro_item {
        width: 48%;
        justify-content: space-between;
        p:nth-child(1) {
          font-size: 22px;
        }
        p:nth-child(2) {
          font-size: 22px;
        }
      }
    }
  }
}
.chart {
  // border-bottom: 16px solid #EFF3F5;
  .nav_wrapper {
    height: 88px;
    position: relative;
    justify-content: center;
    .nav {
      width: 160px;
      height: 46px;
      border-radius: 26px;
      overflow: hidden;
      .nav_item {
        width: 80px;
        height: 46px;
        justify-content: center;
        img {
          width: 22px;
          height: 18px;
          position: relative;
        }
      }
      .active {
        background: rgba(0, 122, 255, 0.1);
      }
    }
  }
  .chart_detail {
    width: 100%;
    position: relative;
  }
}
</style>
<style>
.mint-navbar .mint-tab-item {
  color: #777777;
}
.mint-navbar .mint-tab-item .mint-tab-item-label {
  font-size: 25px;
}
</style>



